import React from 'react';
import {I18nextProvider} from 'react-i18next';
import {environmentContext, useEnvironmentLoader} from './Environment';
import i18n from './i18n';
import {Layout} from './Layout';
import {useLoginManagerHost, UserContext} from './user';
import {Spinner} from '@blueprintjs/core';
import {FlexColumn} from '../Utils/LayoutStyles';
import {apiContext} from '../Api/useBackendApi';

export function Shell() {
  const env = useEnvironmentLoader();
  const loginManager = useLoginManagerHost(env);

  if (env === null || loginManager.isLoggingIn) {
    return (
      <FlexColumn style={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Spinner />
        {/*{loadingUser ? <div>Loading user info...</div> : <div>Logging in...</div>}*/}
      </FlexColumn>
    );
  }

  const data = (
    <UserContext.Provider value={loginManager}>
      <Layout />
    </UserContext.Provider>
  );

  return (
    <I18nextProvider i18n={i18n}>
      <environmentContext.Provider value={env}>
        <apiContext.Provider value={loginManager.authenticatedApi}>{data}</apiContext.Provider>
      </environmentContext.Provider>
    </I18nextProvider>
  );
}
