import {LocationDtoWithId, MessageDestinationDto, ParticipantInfoDto, UserDto, UserId} from '../common/models';
import {useBackendApi} from './Api/useBackendApi';
import React, {useState} from 'react';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {FlexCenterRow, FlexColumn, FlexFiller} from './Utils/LayoutStyles';
import {AsyncButton} from './Utils/AsyncButton';
import {UserChatLink} from './UserChatLink';
import {isModeratorOrOwner} from './helpers';
import {useUser} from './Shell/user';
import {Spinner} from '@blueprintjs/core';

export function ParticipantsListComponent({destination}: {destination: MessageDestinationDto}) {
  const api = useBackendApi();
  const [participants, setParticipants] = useState<ParticipantInfoDto[]>([]);
  const [location, setLocation] = useState<LocationDtoWithId | 'loading'>('loading');

  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      if (destination.type === 'default-location') {
        const l = await wrap(api.getLocation('default'));
        setLocation(l);
        await wrap(api.getBannedUsers(l.id));
      } else if (destination.type === 'chat') {
        setLocation(await wrap(api.getLocation(destination.locationId)));
        await wrap(api.getBannedUsers(destination.locationId));
      }
      setParticipants(await wrap(api.getParticipants(destination)));
    },
    [api, destination]
  );

  const user = useUser();

  const isModerator = location !== 'loading' && user && isModeratorOrOwner(location, user.id);

  return (
    <FlexColumn>
      <div>Participants list:</div>
      {participants.map((x) => (
        <FlexCenterRow key={x.id}>
          <UserChatLink user={x} />
          <FlexFiller />
          {isModerator && !x.isAdmin && (
            <AsyncButton
              onClickAsync={async () => {
                if (!api) return;
                if (location === 'loading') return;
                await api.banUser(location.id, x.id);
                setParticipants(participants.filter((p) => p.id !== x.id));
              }}
            >
              Ban user
            </AsyncButton>
          )}
        </FlexCenterRow>
      ))}
    </FlexColumn>
  );
}
