import React, {createContext, ReactNode, useContext} from 'react';
import {LinkedList, LinkedListNode} from './LinkedList';

export type ImperativeApiRenderItem = {
  key: string;
  render: (key: string) => ReactNode;
};

export type ImperativeApiContextType = {items: LinkedList<ImperativeApiRenderItem>; triggerRender: () => void};

export const ImperativeApiContext = createContext<ImperativeApiContextType | null>(null);

export type RenderFn<T = void> = (args: T) => ReactNode;

export function ImperativeApiContainer(props: {data: ImperativeApiContextType}) {
  return <>{props.data.items.getItems().map(x => x.render(x.key))}</>;
}

let imperativeItemCounter = 0;

export function useImperativeApi() {
  const context = useContext(ImperativeApiContext);
  if (context === null) {
    throw new Error('context is required for imperative api.');
  }

  return {
    add: (render: RenderFn<{key: string; remove: () => void}>) => {
      let node: LinkedListNode<ImperativeApiRenderItem> | null = null;
      let removeImmediately = false;

      function remove() {
        if (node === null) {
          removeImmediately = true;
        } else {
          node.detachSelf();
        }
      }

      node = context.items.add({
        key: (imperativeItemCounter++).toString(),
        render: key => render({key, remove})
      });
      if (removeImmediately) {
        node.detachSelf();
      }
      context.triggerRender();
    }
  };
}
