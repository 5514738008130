import {FlexCenterColumn} from './Utils/LayoutStyles';
import {AsyncButton} from './Utils/AsyncButton';
import React from 'react';
import {useLoginManager} from './Shell/user';

export function LandingComponent() {
  const lm = useLoginManager();
  return (
    <FlexCenterColumn>
      <div>Welcome to breakout!</div>
      <AsyncButton
        onClickAsync={() => {
          return lm.loginAuth0();
        }}
      >
        Login
      </AsyncButton>
      <div>to create a new place</div>
      <div>- OR -</div>
      <div>scan a QR code to join an existing one</div>
    </FlexCenterColumn>
  );
}
