import {
  ChatInfoDto,
  ChatMessagedId,
  ChatMessageDto,
  CurrentLocationInfoDto,
  FileInfoDto,
  JoinLocationDto,
  LocationDto,
  LocationDtoWithId,
  LocationId,
  MessageBodyDto,
  MessageDestinationDto,
  OwnUserDto,
  ParticipantInfoDto,
  QrRegenerationResultDto,
  RegisteredUserDto,
  TemporaryUserRegistrationDto,
  UploadResult,
  UserDto,
  UserId,
} from '../../common/models';
import {date2AwareReviver} from '../../common/utils';

export class HttpApi {
  constructor(
    private apiUrl: string,
    private requestPreprocessor: (options: RequestInfo, o: RequestInit | undefined) => Promise<RequestInfo>
  ) {}

  private async pp<T = void>(u: RequestInfo, i: RequestInit | undefined, contentType?: string | null): Promise<T> {
    const request = await this.requestPreprocessor(u, i);
    if (typeof request !== 'string') {
      if (contentType !== null) {
        request.headers.set('Content-Type', contentType || 'application/json');
      }
    }
    const r = await fetch(request);
    await throwIfNotOk(r);
    const t = await r.text();
    return JSON.parse(t, date2AwareReviver);
  }

  private async post(relativePathWithoutSlash: string): Promise<any> {
    const i: RequestInit = {method: 'post', body: '{}'};
    return await this.pp(new Request(this.apiUrl + '/' + relativePathWithoutSlash, i), i);
  }

  private async postJson(relativePathWithoutSlash: string, payload: object): Promise<any> {
    const i: RequestInit = {method: 'post', body: JSON.stringify(payload)};
    return await this.pp(new Request(this.apiUrl + '/' + relativePathWithoutSlash, i), i);
  }

  private async get(relativePathWithoutSlash: string): Promise<any> {
    const i: RequestInit = {method: 'get'};
    return await this.pp(new Request(this.apiUrl + '/' + relativePathWithoutSlash, i), i);
  }

  private async putJson(relativePathWithoutSlash: string, payload: object): Promise<any> {
    const i: RequestInit = {method: 'put', body: JSON.stringify(payload)};
    return await this.pp(new Request(this.apiUrl + '/' + relativePathWithoutSlash, i), i);
  }

  async me(): Promise<OwnUserDto> {
    const i: RequestInit = {};
    return this.pp(new Request(this.apiUrl + '/me', i), i);
  }

  async getUser(id: UserId): Promise<UserDto> {
    return await this.get(`user/${id}`);
  }

  async createLocation(l: LocationDto): Promise<{id: LocationId}> {
    const i: RequestInit = {method: 'post', body: JSON.stringify(l)};
    return await this.pp(new Request(this.apiUrl + `/location`, i), i);
  }

  async updateLocation(id: LocationId, location: LocationDto): Promise<void> {
    return await this.putJson(`location/${id}`, location);
  }

  async getLocation(id: LocationId | 'default'): Promise<LocationDtoWithId> {
    return this.get(`location/${id}`);
  }

  async getLocationByToken(token: string): Promise<LocationDtoWithId> {
    return this.get(`location-by-token/${token}`);
  }

  async getCurrentLocation(): Promise<CurrentLocationInfoDto> {
    return this.get(`current-location`);
  }

  async getMyLocations(): Promise<LocationDtoWithId[]> {
    const i: RequestInit = {method: 'get'};
    return await this.pp(new Request(this.apiUrl + `/locations`, i), i);
  }

  async uploadMediaFile(file: File): Promise<UploadResult> {
    const formData = new FormData();
    formData.append('theFile', file);
    const i: RequestInit = {method: 'post', body: formData};
    return await this.pp(new Request(this.apiUrl + `/media-file`, i), i, null);
  }

  async getMyMediaFiles(): Promise<FileInfoDto[]> {
    const i: RequestInit = {method: 'get'};
    return await this.pp(new Request(this.apiUrl + `/media-files`, i), i);
  }

  async deleteMediaFile(id: string): Promise<void> {
    const i: RequestInit = {method: 'delete'};
    return await this.pp(new Request(this.apiUrl + `/media-files/${id}`, i), i);
  }

  async sendMessage(message: MessageBodyDto): Promise<{id: ChatMessagedId}> {
    return await this.postJson('messages', message);
  }

  async getMessages(destination: MessageDestinationDto, lastMessageId?: number): Promise<ChatMessageDto[]> {
    if (lastMessageId) return await this.putJson(`messages/${lastMessageId}`, destination);
    return await this.putJson(`messages/undefined`, destination);
  }

  async registerTemporaryUser(data: TemporaryUserRegistrationDto): Promise<RegisteredUserDto> {
    return await this.postJson(`register`, data);
  }

  async getChats(): Promise<ChatInfoDto[]> {
    return await this.get(`chats`);
  }

  async getParticipants(destination: MessageDestinationDto): Promise<ParticipantInfoDto[]> {
    return await this.putJson(`chat/participants`, destination);
  }

  async joinLocation(location: JoinLocationDto): Promise<void> {
    return await this.postJson('locations/join', location);
  }

  async leaveLocation(locationId: LocationId): Promise<void> {
    return await this.post(`locations/leave/${locationId}`);
  }

  async invalidateQrCode(locationId: LocationId): Promise<QrRegenerationResultDto> {
    return await this.post(`locations/invalidate-qr/${locationId}`);
  }

  async addModerator(locationId: LocationId, userId: UserId): Promise<void> {
    return await this.post(`locations/add-moderator/${locationId}/${userId}`);
  }

  async removeModerator(locationId: LocationId, userId: UserId): Promise<void> {
    return await this.post(`locations/remove-moderator/${locationId}/${userId}`);
  }

  async getUserByEmail(email: string): Promise<UserDto> {
    return await this.get(`find-user/email/${email}`);
  }

  async banUser(locationId: LocationId, userId: UserId): Promise<void> {
    return await this.post(`locations/ban-user/${locationId}/${userId}`);
  }

  async getBannedUsers(id: LocationId): Promise<UserDto[]> {
    return await this.get(`locations/banned-users/${id}`);
  }

  async unbanUser(locationId: LocationId, userId: UserId) {
    return await this.post(`locations/unban-user/${locationId}/${userId}`);
  }
}

async function throwIfNotOk(r: Response) {
  if (r.status.toString(10)[0] !== '2') {
    const body = await r.json();
    throw new Error(body.message || 'Error occurred');
  }
}
