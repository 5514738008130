import {LocationId, MessageDestinationDto} from '../common/models';
import {useLoginManager} from './Shell/user';
import {useBackendApi} from './Api/useBackendApi';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {FlexColumn} from './Utils/LayoutStyles';
import {MobileHeaderComponent} from './MobileHeaderComponent';
import {SmallSpinner} from './Utils/SmallSpinner';
import {Menu, MenuItem} from '@blueprintjs/core';
import {LandingRoute} from './Shell/RouteDefinitions';
import {AsyncButton} from './Utils/AsyncButton';
import {ChatComponent} from './ChatComponent';

export function ChatWithTitleComponent({destination}: {destination: MessageDestinationDto}) {
  const lm = useLoginManager();
  const needLogin = !lm.isLoggingIn && !lm.loggedInUser;
  const api = useBackendApi();
  const [name, setName] = useState<{name: string} | 'loading'>('loading');
  const [locationId, setLocationId] = useState<LocationId>();
  const history = useHistory();

  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      if (destination.type === 'default-location') {
        const l = await wrap(api.getLocation('default'));
        setLocationId(l.id);
        setName(l);
      } else if (destination.type === 'chat') {
        const l = await wrap(api.getLocation(destination.locationId));
        setLocationId(l.id);
        setName(l);
      } else {
        const l = await wrap(api.getUser(destination.userId));
        setName(l);
      }
    },
    [api, destination]
  );

  return (
    <FlexColumn style={{flex: '1 1 0'}}>
      <MobileHeaderComponent
        title={name !== 'loading' ? <div>{name.name}</div> : <SmallSpinner />}
        drawerContent={
          <Menu>
            {destination.type !== 'direct' && (
              <MenuItem
                text='Leave current location'
                onClick={async () => {
                  if (!api) return;
                  if (!locationId) return;
                  await api.leaveLocation(locationId);
                  if (!lm.isAuth0Login) {
                    lm.logout();
                  }
                  history.push(LandingRoute.makePath());
                }}
              />
            )}
          </Menu>
        }
      />
      {needLogin && (
        <AsyncButton
          onClickAsync={() => {
            return lm.loginAuth0();
          }}
        >
          Login
        </AsyncButton>
      )}
      <ChatComponent destination={destination} />
    </FlexColumn>
  );
}
