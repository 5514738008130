import {Toaster, ToastProps} from '@blueprintjs/core';
import {createContext, useContext} from 'react';

let globalIdCounter = 0;

export const ToastContext = createContext<{toaster: Toaster | null}>({toaster: null});

export function useSnackbar(): {addText: (text: string) => void; add: (props: ToastProps) => void} {
  const context = useContext(ToastContext);

  return {
    addText: str => {
      if (context.toaster === null) {
        throw new Error('missing context');
      }
      context.toaster.show({message: str}, (globalIdCounter++).toString());
    },
    add: props => {
      if (context.toaster === null) {
        throw new Error('missing context');
      }
      context.toaster.show(props, (globalIdCounter++).toString());
    }
  };
}
