import {FlexCenterColumn, FlexColumn} from './Utils/LayoutStyles';
import React, {useMemo} from 'react';
import {MobileFooterComponent} from './MobileFooterComponent';
import {MainRouteParams} from './Shell/RouteDefinitions';
import {HomePageComponent} from './HomePageComponent';
import {PrivateMessagesComponent} from './PrivateMessagesComponent';
import {useIsSmall} from './Shell/adaptive';
import {ChatWithTitleComponent} from './ChatWithTitleComponent';
import {DashboardComponent} from './DashboardComponent';
import {useLoginManager} from './Shell/user';
import {AsyncButton} from './Utils/AsyncButton';
import {Spinner} from '@blueprintjs/core';

export function MainComponent({params}: {params: MainRouteParams}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params2 = useMemo(() => params, [JSON.stringify(params)]);
  const isSmall = useIsSmall();
  const lm = useLoginManager();

  if (lm.isLoggingIn) {
    return <Spinner />;
  }

  if (!lm.loggedInUser) {
    return (
      <AsyncButton
        onClickAsync={() => {
          return lm.loginAuth0();
        }}
      >
        Login
      </AsyncButton>
    );
  }

  if (isSmall && !lm.loggedInUser.defaultLocation) {
    return (
      <FlexCenterColumn>
        <div>You are not participating in any location</div>
        <div>Use QR code to join one</div>
      </FlexCenterColumn>
    );
  }

  if (!isSmall) {
    if (params2 !== 'home' && params2 !== 'chats') {
      return <DashboardComponent destination={params2.destination} />;
    }
    return <></>;
  }

  function getContent() {
    if (params2 === 'home') return <HomePageComponent />;
    if (params2 === 'chats') return <PrivateMessagesComponent selectedLocation={undefined} />;

    return <ChatWithTitleComponent destination={params2.destination} />;
  }

  return (
    <FlexColumn style={{flex: '1 1 0'}}>
      {getContent()}
      <MobileFooterComponent />
    </FlexColumn>
  );
}
