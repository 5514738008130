import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {grayTwo} from '../Shell/Styles';

export const StyledCircledText = styled.div<{size: number}>`
  border-radius: 50%;
  border: 1px solid ${grayTwo};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${grayTwo};
  font-size: 12px;
`;

export function CircledText({children}: {children: ReactNode}) {
  return <StyledCircledText size={20}>{children}</StyledCircledText>;
}
