import {useAuth0} from '@auth0/auth0-react';
import {createContext, useContext, useState} from 'react';
import {OwnUserDto, RegisteredUserDto, TemporaryUserRegistrationDto} from '../../common/models';
import {HttpApi} from '../Api/HttpApi';
import {makeAuthenticatedHttpApi} from '../Api/useBackendApi';
import {useAsyncEffect} from '../Utils/useAsyncEffect';
import {Environment} from './Environment';

export const UserContext = createContext<LoginManager | undefined>(undefined);

export type LoginManager = {
  loggedInUser: OwnUserDto | undefined;
  isLoggingIn: boolean;
  isAuth0Login: boolean | undefined;
  authenticatedApi: HttpApi | undefined;
  loginAuth0: (path?: string) => Promise<void>;
  loginTemporary: (dto: TemporaryUserRegistrationDto) => Promise<RegisteredUserDto>;
  logout(): Promise<void>;
};

export function useLoginManagerHost(env: Environment | null): LoginManager {
  const auth0 = useAuth0();
  const [loginState, setLoginState] = useState<{
    user: OwnUserDto | undefined;
    authenticatedApi: HttpApi | undefined;
    isAuthenticated: true | false | 'loading';
    isAuth0Login?: boolean;
  }>({
    user: undefined,
    authenticatedApi: undefined,
    isAuthenticated: 'loading',
  });

  useAsyncEffect(
    async ({wrap}) => {
      if (!env) return;
      if (auth0.isLoading) return;

      const ownToken = localStorage.getItem('jwt-token');

      let api: HttpApi | undefined = undefined;
      let isAuth0 = false;
      if (auth0.isAuthenticated) {
        api = makeAuthenticatedHttpApi(env.apiUrl, auth0.getAccessTokenSilently);
        isAuth0 = true;
      } else if (ownToken) {
        api = makeAuthenticatedHttpApi(env.apiUrl, () => Promise.resolve(ownToken));
      }

      if (!api) {
        setLoginState({...loginState, isAuthenticated: false});
        return;
      }

      const user = await wrap(api.me());
      setLoginState({...loginState, isAuthenticated: true, user, authenticatedApi: api, isAuth0Login: isAuth0});
    },
    [env, auth0.isLoading]
  );

  return {
    isAuth0Login: loginState.isAuth0Login,
    isLoggingIn: loginState.isAuthenticated === 'loading',
    loggedInUser: loginState.user,
    authenticatedApi: loginState.authenticatedApi,
    logout: async () => {
      if (auth0.isAuthenticated) {
        auth0.logout();
      } else {
        localStorage.removeItem('jwt-token');
      }
    },
    loginAuth0: (path?: string) =>
      auth0.loginWithRedirect({redirectUri: env && path ? env.appUrl + '#' + path : undefined}),
    loginTemporary: async (dto) => {
      if (!env) throw new Error();
      if (auth0.isAuthenticated) {
        auth0.logout({localOnly: true});
      }
      const api = new HttpApi(env.apiUrl, (options) => Promise.resolve(options));
      const result = await api.registerTemporaryUser(dto);
      localStorage.setItem('jwt-token', result.jwtToken);
      const authenticatedApi = makeAuthenticatedHttpApi(env.apiUrl, () => Promise.resolve(result.jwtToken));
      setLoginState({
        ...loginState,
        isAuthenticated: true,
        isAuth0Login: false,
        authenticatedApi,
        user: {id: result.id, name: dto.username, avatarImageId: undefined, defaultLocation: undefined},
      });
      return result;
    },
  };
}

export function useLoginManager(): LoginManager {
  const context = useContext(UserContext);
  if (!context) throw new Error();
  return context;
}

export function useUserLoggedIn() {
  const {isAuthenticated} = useAuth0();
  if (isAuthenticated) return isAuthenticated;

  return false;
}

export function useUser(): OwnUserDto | undefined {
  const context = useContext(UserContext);
  if (!context) {
    return undefined;
  }
  return context.loggedInUser;
}
