import {FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from './Utils/LayoutStyles';
import {useBackendApi} from './Api/useBackendApi';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import React, {useState} from 'react';
import {ChatInfoDto, isUser, MessageDestinationDto, UserDto, UserId} from '../common/models';
import {MobileHeaderComponent} from './MobileHeaderComponent';
import {SmallSpinner} from './Utils/SmallSpinner';
import {StyledRouterLink} from './Utils/NavigationLinks';
import {MainRoute} from './Shell/RouteDefinitions';
import {format, isSameDay} from 'date-fns';
import {Spinner} from '@blueprintjs/core';

export function PrivateMessagesComponent({selectedLocation}: {selectedLocation: MessageDestinationDto | undefined}) {
  const api = useBackendApi();
  const [chats, setChats] = useState<ChatInfoDto[] | 'loading'>('loading');

  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      setChats(await wrap(api.getChats()));
    },
    [api]
  );

  const missingUser =
    selectedLocation?.type === 'direct' &&
    chats !== 'loading' &&
    !chats.find((p) => p.otherUser.id === selectedLocation.userId);

  return (
    <FlexColumn style={{flex: '1 1 0', alignItems: 'stretch'}}>
      <MobileHeaderComponent title={chats === 'loading' ? <SmallSpinner /> : 'Chats'} />
      {chats !== 'loading' && chats.length === 0 && (
        <div style={{alignSelf: 'center'}}>No chats. You can chat with people you meet in the location chat</div>
      )}
      {chats !== 'loading' &&
        chats.map((x) => {
          const isSelected = selectedLocation?.type === 'direct' && selectedLocation.userId === x.otherUser.id;
          return (
            <StyledRouterLink
              key={x.otherUser.id}
              to={MainRoute.makePath({
                destination: {
                  type: 'direct',
                  userId: x.otherUser.id,
                },
              })}
              style={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                padding: '10px',
                background: isSelected ? '#eee' : 'white',
              }}
            >
              <FlexColumn style={{flex: '1 1 0', alignItems: 'stretch'}}>
                <FlexRow style={{marginBottom: '5px', fontSize: '11px'}}>
                  <div> {isUser(x.otherUser) ? x.otherUser.name : 'Deleted user'}</div>
                  <FlexFiller />
                  <div>
                    {isSameDay(new Date(), x.sentAt)
                      ? format(x.sentAt, 'HH:mm')
                      : format(x.sentAt, 'EEEE LLL d, HH:mm')}
                  </div>
                </FlexRow>
                <div>{x.text}</div>
              </FlexColumn>
            </StyledRouterLink>
          );
        })}
      {missingUser && selectedLocation?.type === 'direct' && <NewChatLinkComponent userId={selectedLocation.userId} />}
    </FlexColumn>
  );
}

function NewChatLinkComponent({userId}: {userId: UserId}) {
  const api = useBackendApi();
  const [user, setUser] = useState<UserDto | 'loading'>('loading');

  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      setUser(await wrap(api.getUser(userId)));
    },
    [api, userId]
  );

  return (
    <FlexCenterRow>
      {user === 'loading' ? (
        <Spinner />
      ) : (
        <>
          <StyledRouterLink
            to={MainRoute.makePath({
              destination: {
                type: 'direct',
                userId: user.id,
              },
            })}
            style={{
              flex: '1 1 0',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ccc',
              padding: '10px',
              background: '#eee',
            }}
          >
            <FlexColumn style={{flex: '1 1 0', alignItems: 'stretch'}}>
              <FlexRow style={{marginBottom: '5px', fontSize: '11px'}}>
                <div> {isUser(user) ? user.name : 'Deleted user'}</div>
                <FlexFiller />
              </FlexRow>
              <div>New chat</div>
            </FlexColumn>
          </StyledRouterLink>
        </>
      )}
    </FlexCenterRow>
  );
}
