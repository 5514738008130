import {LocationDtoWithId, MessageDestinationDto} from '../common/models';
import {useBackendApi} from './Api/useBackendApi';
import {useHistory} from 'react-router';
import React, {ChangeEvent, useState} from 'react';
import {useLoginManager, useUser} from './Shell/user';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {Button, Classes, Dialog, H4, Icon, InputGroup, Spinner, TextArea} from '@blueprintjs/core';
import {LinkLikeButton, StyledRouterLink} from './Utils/NavigationLinks';
import {LandingRoute, MainRoute} from './Shell/RouteDefinitions';
import {FlexCenterRow, FlexFiller} from './Utils/LayoutStyles';
import {StyledCircledText} from './Utils/CircledText';
import {AsyncButton} from './Utils/AsyncButton';
import {isModeratorOrOwner} from './helpers';

function EditLocationComponent({location, onClose}: {location: LocationDtoWithId; onClose: () => void}) {
  const [name, setName] = useState(location.name);
  const [description, setDescription] = useState(location.description);

  const api = useBackendApi();

  return (
    <Dialog isOpen={true} onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        <H4>Edit location</H4>
        <InputGroup
          placeholder='Name'
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />
        <TextArea
          placeholder='Description'
          value={description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
        />
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <AsyncButton
            onClickAsync={async () => {
              if (!api) return;
              await api.updateLocation(location.id, {name, description});
              onClose();
            }}
          >
            Save
          </AsyncButton>
        </div>
      </div>
    </Dialog>
  );
}

export function LocationsListComponent({selectedLocation}: {selectedLocation: MessageDestinationDto}) {
  const api = useBackendApi();
  const history = useHistory();
  const [locations, setLocations] = useState<LocationDtoWithId[] | 'loading'>('loading');
  const [showNewLocation, setShowNewLocation] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [editingLocation, setEditingLocation] = useState<LocationDtoWithId>();

  const user = useUser();
  const lm = useLoginManager();

  const {trigger: loadLocations} = useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      setLocations(await wrap(api.getMyLocations()));
    },
    [api]
  );

  if (locations === 'loading') return <Spinner />;

  return (
    <>
      {locations.map((x) => {
        const isSelected =
          (selectedLocation.type === 'default-location' && x.isDefaultLocation) ||
          (selectedLocation.type === 'chat' && x.id === selectedLocation.locationId);
        return (
          <StyledRouterLink
            key={x.id}
            to={MainRoute.makePath({destination: {type: 'chat', locationId: x.id}})}
            style={{borderBottom: '1px solid #ccc', background: isSelected ? '#eee' : 'white'}}
          >
            <FlexCenterRow>
              <StyledCircledText size={40} style={{margin: '10px'}}>
                <Icon icon='map-marker' />
              </StyledCircledText>
              <div>{x.name}</div>
              <FlexFiller />
              {isModeratorOrOwner(x, user?.id) ? (
                <>
                  <Button onClick={() => setEditingLocation(x)} icon='settings' />
                  <Icon icon='crown' style={{marginRight: '10px'}} />
                </>
              ) : (
                <AsyncButton
                  onClickAsync={async () => {
                    if (!api) return;
                    await api.leaveLocation(x.id);
                    if (!lm.isAuth0Login) {
                      lm.logout();
                    }
                    history.push(LandingRoute.makePath());
                  }}
                  icon='log-out'
                />
              )}
            </FlexCenterRow>
          </StyledRouterLink>
        );
      })}
      <LinkLikeButton onClick={() => setShowNewLocation(true)}>
        <FlexCenterRow>
          <StyledCircledText size={40} style={{margin: '10px'}}>
            <Icon icon='plus' />
          </StyledCircledText>
          <div>Add Location</div>
        </FlexCenterRow>
      </LinkLikeButton>
      <Dialog isOpen={showNewLocation} onClose={() => setShowNewLocation(false)}>
        <div className={Classes.DIALOG_BODY}>
          <H4>New location</H4>
          <InputGroup
            placeholder='Name'
            value={newLocationName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewLocationName(e.target.value)}
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <AsyncButton
              onClickAsync={async () => {
                if (!api) return;
                const id = await api.createLocation({name: newLocationName, description: ''});
                loadLocations();
                history.push(MainRoute.makePath({destination: {type: 'chat', locationId: id.id}}));
                setShowNewLocation(false);
              }}
            >
              Create
            </AsyncButton>
          </div>
        </div>
      </Dialog>
      {editingLocation && (
        <EditLocationComponent location={editingLocation} onClose={() => setEditingLocation(undefined)} />
      )}
    </>
  );
}
