import {useEnvironment} from './Shell/Environment';
import {QrCodeRoute} from './Shell/RouteDefinitions';
import QRCode from 'qrcode.react';
import React from 'react';

export function QrCodeLoginComponent({token}: {token: string}) {
  const env = useEnvironment();
  const url = `${env.appUrl}/#${QrCodeRoute.makePath({locationToken: token})}`;

  return (
    <>
      <QRCode value={url} />
      <a style={{wordBreak: 'break-all'}} href={url}>
        {url}
      </a>
    </>
  );
}
