import {createContext, useContext, useEffect, useState} from 'react';

export type Environment = {
  readonly apiUrl: string;
  readonly appUrl: string;
  readonly useMsal: 'true' | string;
};

export const environmentContext = createContext<Environment | null>(null);

export function useEnvironment(): Environment {
  const environment = useContext(environmentContext);
  if (environment === null) {
    throw new Error('environment is not loaded.');
  }
  return environment;
}

export function useEnvironmentLoader(): Environment | null {
  const [env, setEnv] = useState<Environment | null>(null);
  useEffect(() => {
    setEnv((window as any).environment);
  }, []);
  return env;
}
