import {FlexRow} from './Utils/LayoutStyles';
import {MainRoute} from './Shell/RouteDefinitions';
import {Icon} from '@blueprintjs/core';
import {StyledRouterLink} from './Utils/NavigationLinks';
import middle from './middle_button.png';
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

export function MobileFooterComponent() {
  const radius = '10px';
  const iconSize = 30;

  const imageWidth = '110px';
  const imageHeight = '74px';

  return (
    <FlexRow style={{background: '#7A889A', color: 'white', borderTopLeftRadius: radius, borderTopRightRadius: radius}}>
      <StyledButton to={MainRoute.makePath('home')}>
        <Icon icon='home' size={iconSize} />
      </StyledButton>
      <div style={{flex: '0 0 auto', width: imageWidth, position: 'relative'}}>
        <StyledRouterLink to={MainRoute.makePath({destination: {type: 'default-location'}})}>
          <img alt='' src={middle} style={{position: 'absolute', bottom: 0, width: imageWidth, height: imageHeight}} />
        </StyledRouterLink>
      </div>
      <StyledButton to={MainRoute.makePath('chats')}>
        <Icon icon='chat' size={iconSize} />
      </StyledButton>
    </FlexRow>
  );
}

export const StyledButton = styled(Link)`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
  height: 58px;
  color: white;
`;
