import {MessageDestinationDto} from '../common/models';
import {FlexCenterColumn, FlexColumn, FlexFiller, FlexRow} from './Utils/LayoutStyles';
import {LocationsListComponent} from './LocationsListComponent';
import {PrivateMessagesComponent} from './PrivateMessagesComponent';
import {ChatComponent} from './ChatComponent';
import {ParticipantsListComponent} from './ParticipantsListComponent';
import {AdminZoneComponent} from './AdminZoneComponent';
import React from 'react';
import {useUser} from './Shell/user';

export function DashboardComponent({destination}: {destination: MessageDestinationDto}) {
  const user = useUser();

  return (
    <FlexRow style={{flex: '1 1 0', alignSelf: 'stretch'}}>
      <FlexColumn style={{flex: '1 1 0', borderRight: '1px solid #eee'}}>
        <LocationsListComponent selectedLocation={destination} />
        <PrivateMessagesComponent selectedLocation={destination} />
      </FlexColumn>
      <FlexCenterColumn style={{flex: '2 2 0', alignSelf: 'stretch'}}>
        {destination.type === 'default-location' && user?.defaultLocation ? (
          <ChatComponent destination={destination} />
        ) : (
          'select chat to start'
        )}
      </FlexCenterColumn>
      <FlexColumn style={{flex: '1 1 0'}}>
        {user?.defaultLocation && (
          <>
            <FlexFiller>
              <ParticipantsListComponent destination={destination} />
            </FlexFiller>
            <FlexFiller>
              <AdminZoneComponent destination={destination} />
            </FlexFiller>
          </>
        )}
      </FlexColumn>
    </FlexRow>
  );
}
