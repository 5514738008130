import {
  ChatMessagedId,
  ChatMessageDto,
  isMessageDestinationDto,
  MessageDestinationDto,
  typedString,
  UserDto,
} from '../common/models';
import {useBackendApi} from './Api/useBackendApi';
import React, {useEffect, useRef, useState} from 'react';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {useUser} from './Shell/user';
import {Date2, makeDate2} from '../common/utils';
import {FlexCenterRow, FlexColumn, FlexFiller, FlexRow} from './Utils/LayoutStyles';
import {Button, Icon, InputGroup, Spinner} from '@blueprintjs/core';
import {format, isSameDay} from 'date-fns';
import {SmallSpinner} from './Utils/SmallSpinner';
import {StyledRouterLink} from './Utils/NavigationLinks';
import {MainRoute} from './Shell/RouteDefinitions';
import {StyledCircledText} from './Utils/CircledText';

export function ChatComponent({destination}: {destination: MessageDestinationDto}) {
  const api = useBackendApi();
  const user = useUser();
  const [messages, setMessages] = useState<Message[] | 'loading'>('loading');
  const lastKnown = useRef<number>();
  const [text, setText] = useState('');

  const [fetchParams, setFetchParams] = useState<{destination: MessageDestinationDto}>({destination});

  useEffect(() => {
    setMessages('loading');
    lastKnown.current = undefined;
    setFetchParams({destination});
  }, [destination]);

  useAsyncEffect(
    async ({wrap, isCanceled}) => {
      if (!api) return;
      const m = await wrap(api.getMessages(destination, lastKnown.current));
      if (messages !== 'loading') {
        const filteredMessages = m.filter((x) => !messages.find((y) => y.knownId === x.id));
        setMessages([...messages, ...filteredMessages.map(mapMessage)]);
        if (m.length !== 0) lastKnown.current = m[m.length - 1]?.index ?? lastKnown.current;
      } else {
        setMessages([...m.map(mapMessage)]);
        if (m.length !== 0) lastKnown.current = m[m.length - 1]?.index ?? lastKnown.current;
      }

      setTimeout(
        () => {
          if (isCanceled()) return;
          setFetchParams({...fetchParams});
        },
        m.length !== 0 ? 1000 : 5000
      );
    },
    [api, fetchParams]
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // otherwise does not scroll all the way down on load (on FF)
    const t = setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
    }, 100);
    return () => {
      clearTimeout(t);
    };
  }, [messages]);

  function sendMessage() {
    if (!user) return;
    if (messages === 'loading') return;
    setMessages([
      ...messages,
      {
        key: typedString<ChatMessagedId>(mongoObjectId()),
        text,
        from: user,
        sentAt: makeDate2(),
        knownId: destination,
      },
    ]);
    setText('');
  }

  return (
    <FlexColumn style={{flex: '1 1 0'}}>
      <FlexColumn style={{flex: '1 1 0', overflow: 'auto', alignItems: 'stretch'}}>
        {messages === 'loading' && <Spinner />}
        {messages !== 'loading' && messages.map((m) => <MessageComponent key={m.key} message={m} />)}
        {messages !== 'loading' && messages.length === 0 && <div>Be the first to start the conversion</div>}
        <div ref={messagesEndRef} />
      </FlexColumn>
      <FlexCenterRow style={{margin: '10px'}}>
        <InputGroup
          fill={true}
          value={text}
          onChange={(x) => setText(x.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
              sendMessage();
            }
          }}
        />
        <Button onClick={() => sendMessage()} icon='send-message' style={{marginLeft: '10px'}} />
      </FlexCenterRow>
    </FlexColumn>
  );

  function mapMessage(x: ChatMessageDto): Message {
    return {
      knownId: x.id,
      sentAt: x.date,
      text: x.text,
      from: x.from,
      key: x.id,
    };
  }
}

type Message = {
  key: ChatMessagedId;
  knownId: ChatMessagedId | MessageDestinationDto;
  text: string;
  sentAt: Date2;
  from: UserDto | 'unknown';
};

function MessageComponent({message}: {message: Message}) {
  const api = useBackendApi();
  const user = useUser();

  const [state, setState] = useState<'sending' | 'sent' | 'error'>('sending');
  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      const knownId = message.knownId;
      if (isMessageDestinationDto(knownId)) {
        const {id} = await wrap(
          api.sendMessage({
            text: message.text,
            to: knownId,
          })
        );
        message.knownId = id;
        setState('sent');
      } else {
        setState('sent');
      }
    },
    [api, message]
  );

  const isOwn = message.from !== 'unknown' && user?.id === message.from.id;
  return (
    <FlexCenterRow style={{margin: '10px'}}>
      <div style={{width: '60px'}}>
        {!isOwn &&
          (message.from !== 'unknown' ? (
            <StyledRouterLink to={MainRoute.makePath({destination: {type: 'direct', userId: message.from.id}})}>
              <StyledCircledText size={50}>
                <Icon icon='person' size={25} />
              </StyledCircledText>
            </StyledRouterLink>
          ) : (
            <StyledCircledText size={50}>
              <Icon icon='person' size={25} />
            </StyledCircledText>
          ))}
      </div>
      <FlexColumn
        style={{background: isOwn ? '#444D59' : '#EEEEEE', padding: '5px 10px', flex: '1 1 0', borderRadius: '5px'}}
      >
        <FlexRow>
          {!isOwn && (
            <div style={{color: '#FC385C', marginBottom: '5px'}}>
              {message.from === 'unknown' ? 'deleted user' : message.from.name}
            </div>
          )}
          <FlexFiller />
          {state === 'sending' ? <SmallSpinner /> : state === 'error' && <Icon icon='error' />}
          <div style={{color: isOwn ? '#A0A5AB' : '#F5C4CD', fontSize: '11px'}}>
            {isSameDay(new Date(), message.sentAt)
              ? format(message.sentAt, 'hh:mm')
              : format(message.sentAt, 'EEEE LLL d, hh:mm')}
          </div>
        </FlexRow>
        <div style={{color: isOwn ? 'white' : undefined}}>{message.text}</div>
      </FlexColumn>
    </FlexCenterRow>
  );
}

const mongoObjectId = function () {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};
