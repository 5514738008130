import React, {useContext, useMemo} from 'react';
import {HashRouter} from 'react-router-dom';
import styled from 'styled-components';
import {LinkedList} from '../Utils/LinkedList';
import {ImperativeApiContainer, ImperativeApiContext, ImperativeApiContextType} from '../Utils/makeImperativeApi';
import {ToastContext} from '../Utils/useSnackbar';
import {Routes} from './Routes';
import {AdaptiveContext} from './adaptive';
import {useForceUpdate} from '../Utils/useForceUpdate';
import {OverlayToaster, ResizeSensor} from '@blueprintjs/core';

const StyledAppRoot = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: visible;
`;

const ContentWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
`;

export function Layout() {
  const adaptiveContext = useContext(AdaptiveContext);
  const toastContext = useContext(ToastContext);
  const forceUpdate = useForceUpdate();
  const imperativeItems = useMemo<ImperativeApiContextType>(
    () => ({
      items: new LinkedList(),
      triggerRender: () => {
        forceUpdate();
      },
    }),
    [forceUpdate]
  );

  return (
    <HashRouter>
      <ResizeSensor
        onResize={(s) => {
          adaptiveContext.width = s[s.length - 1].contentRect.width;
          forceUpdate();
        }}
      >
        <StyledAppRoot>
          <ImperativeApiContext.Provider value={imperativeItems}>
            <ContentWrapperStyle>
              <Routes />
            </ContentWrapperStyle>
          </ImperativeApiContext.Provider>
        </StyledAppRoot>
      </ResizeSensor>
      <ImperativeApiContainer data={imperativeItems} />
      <OverlayToaster
        ref={(ref) => {
          toastContext.toaster = ref;
        }}
      />
    </HashRouter>
  );
}
