export class Date2Impl extends Date {
  constructor(s?: string | Date) {
    if (s) super(s);
    else super();
  }
  toJSON(_?: any): any {
    return {
      __type: 'Date',
      value: this.toISOString()
    };
  }
}

export function makeDate2(s?: string | Date): Date2 {
  return new Date2Impl(s) as Date2;
}

export type Date2 = Date & {
  __brand: 'Date2';
};

export function date2AwareReviver(_key: string, value: any) {
  if (value && value.__type === 'Date') {
    return makeDate2(value.value);
  }
  return value;
}
