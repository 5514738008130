import {LocationDtoWithId, MessageDestinationDto, UserDto} from '../common/models';
import {useBackendApi} from './Api/useBackendApi';
import React, {useState} from 'react';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {FlexCenterRow, FlexColumn} from './Utils/LayoutStyles';
import {QrCodeLoginComponent} from './QrCodeLoginComponent';
import {useUser} from './Shell/user';
import {AsyncButton} from './Utils/AsyncButton';
import {useForceUpdate} from './Utils/useForceUpdate';
import {isModeratorOrOwner} from './helpers';
import {InputGroup} from '@blueprintjs/core';
import {UserChatLink} from './UserChatLink';

export function AdminZoneComponent({destination}: {destination: MessageDestinationDto}) {
  const api = useBackendApi();
  const user = useUser();
  const forceUpdate = useForceUpdate();

  const [location, setLocation] = useState<LocationDtoWithId>();
  const [email, setEmail] = useState('');
  const [bannedUsers, setBannedUsers] = useState<UserDto[] | 'loading'>('loading');

  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      if (destination.type === 'default-location') {
        const l = await wrap(api.getLocation('default'));
        setLocation(l);
        await wrap(api.getBannedUsers(l.id));
      } else if (destination.type === 'chat') {
        setLocation(await wrap(api.getLocation(destination.locationId)));
        await wrap(api.getBannedUsers(destination.locationId));
      }
    },
    [api, destination]
  );

  if (!location) {
    return <></>;
  }

  const isModerator = isModeratorOrOwner(location, user?.id);

  return (
    <FlexColumn>
      {location.token && (
        <>
          <div>Distribute the QR code or link below to allow other users to join</div>
          <QrCodeLoginComponent token={location.token} />
        </>
      )}
      {isModerator && (
        <AsyncButton
          onClickAsync={async () => {
            if (!api) return;
            if (!location) return;
            const newToken = await api.invalidateQrCode(location.id);
            setLocation({...location, token: newToken.token});
          }}
        >
          Generate new QR code
        </AsyncButton>
      )}
      <div>Owner:</div>
      <div>{location.owner === 'deleted' ? 'Deleted' : <UserChatLink user={location.owner} />}</div>
      <div>Moderators list:</div>
      {location.moderators.map((x) => (
        <div key={x.id}>
          <UserChatLink user={x} />
          {isModerator && (
            <AsyncButton
              onClickAsync={async () => {
                if (!api) return;
                if (!location) return;
                await api.removeModerator(location.id, x.id);
                location.moderators = location.moderators.filter((m) => m.id !== x.id);
                forceUpdate();
              }}
              icon='delete'
            />
          )}
        </div>
      ))}

      {isModerator && (
        <>
          <div>Add new moderator:</div>
          <div>Email:</div>
          <FlexCenterRow>
            <InputGroup fill={false} value={email} onChange={(x) => setEmail(x.target.value)} />
            <AsyncButton
              onClickAsync={async () => {
                if (!api) return;
                if (!location) return;
                const user = await api.getUserByEmail(email);
                await api.addModerator(location.id, user.id);
              }}
            >
              Add
            </AsyncButton>
          </FlexCenterRow>
        </>
      )}

      {isModerator && (
        <>
          <div>Banned users:</div>
          {bannedUsers !== 'loading' &&
            bannedUsers.map((x) => (
              <FlexCenterRow>
                <UserChatLink user={x} />
                <AsyncButton
                  onClickAsync={async () => {
                    if (!api) return;
                    if (!location) return;
                    await api.unbanUser(location.id, x.id);
                    setBannedUsers(bannedUsers.filter((p) => p.id !== x.id));
                  }}
                >
                  Ban user
                </AsyncButton>
              </FlexCenterRow>
            ))}
        </>
      )}
    </FlexColumn>
  );
}
