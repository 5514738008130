import {Date2} from './utils';

export function typedString<T extends string>(s: string): T {
  return s as T;
}

export type UserId = string & {readonly __brand__: unique symbol};

export type DeletedUserInfo = {
  id: UserId;
};

export type UserDto = {
  id: UserId;
  name: string;
  avatarImageId: string | undefined;
};

export type OwnUserDto = UserDto & {
  defaultLocation: LocationId | undefined;
};

export function isUser(x: any): x is UserDto {
  return typeof x.name === 'string';
}

export type TemporaryUserRegistrationDto = {
  username: string;
  locationToken: string;
};

export type ParticipantInfoDto = UserDto & {
  isAdmin: boolean;
};

export type ChatInfoDto = {
  otherUser: UserDto | DeletedUserInfo;
  text: string;
  sentAt: Date2;
};

export type RegisteredUserDto = {id: UserId; locationId: LocationId; jwtToken: string};

export type UploadResult =
  | {
      id: string;
    }
  | 'too-big';

export type PmMessageDestinationDto = {type: 'direct'; userId: UserId};
export type ChatMessageDestinationDto = {type: 'chat'; locationId: LocationId};

export type MessageDestinationDto = {type: 'default-location'} | PmMessageDestinationDto | ChatMessageDestinationDto;

export function isMessageDestinationDto(x: any): x is MessageDestinationDto {
  return typeof x === 'object' && (x.type === 'direct' || x.type === 'chat' || x.type === 'default-location');
}

export type MessageBodyDto = {
  text: string;
  to: MessageDestinationDto;
};

export type ChatMessagedId = string & {readonly __brand__: unique symbol};

export type ChatMessageDto = {
  id: ChatMessagedId;
  text: string;
  from: UserDto | 'unknown';
  date: Date2;
  index: number;
};

export type LocationId = string & {readonly __brand__: unique symbol};

export type LocationDtoWithId = LocationDto & {
  id: LocationId;
  isDefaultLocation: boolean;
  owner: UserDto | 'deleted';
  moderators: UserDto[];
  token?: string;
};

export type LocationDto = {
  name: string;
  description: string;
};

export type LocationInfoDto = LocationDto;

export type JoinLocationDto = {
  locationId: LocationId;
  stayDurationDays: number;
};

export type CurrentLocationInfoDto = {
  info: LocationInfoDto | 'no-location';
};

export type FileInfoDto = {
  downloadUrl: string;
  id: string;
  name: string;
  lengthSeconds: number;
};

export type QrRegenerationResultDto = {
  token: string;
};

export type IdFor<t> = string & {__brand: t};
