import {FlexColumn} from './Utils/LayoutStyles';
import {MobileHeaderComponent} from './MobileHeaderComponent';
import {useBackendApi} from './Api/useBackendApi';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {useState} from 'react';
import {CurrentLocationInfoDto} from '../common/models';
import {SmallSpinner} from './Utils/SmallSpinner';

export function HomePageComponent() {
  const api = useBackendApi();
  const [location, setLocation] = useState<CurrentLocationInfoDto | 'loading'>('loading');

  useAsyncEffect(
    async ({wrap}) => {
      if (!api) return;
      const l = await wrap(api.getLocation('default'));
      setLocation({info: l});
    },
    [api]
  );

  if (location === 'loading') {
    return (
      <FlexColumn style={{flex: '1 1 0'}}>
        <MobileHeaderComponent title={<SmallSpinner />} />
      </FlexColumn>
    );
  }

  if (location.info === 'no-location') {
    return (
      <FlexColumn style={{flex: '1 1 0'}}>
        <MobileHeaderComponent title='' />
        <div>You have not joined any places. Scan a QR code to join.</div>
      </FlexColumn>
    );
  }

  return (
    <FlexColumn style={{flex: '1 1 0'}}>
      <MobileHeaderComponent title={location.info.name} />
      <div>{location.info.description}</div>
      <div>You can contact people in the chat.</div>
    </FlexColumn>
  );
}
