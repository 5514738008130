import styled, {css} from 'styled-components';

export const contentMaxWidth = '1200px';

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const FlexFiller = styled.div`
  flex: 1 1 0;
`;

export const FlexCenterContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
