import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {LandingRoute, MainRoute, QrCodeRoute} from './RouteDefinitions';
import {QrCodeComponent} from '../QrCodeComponent';
import {MainComponent} from '../MainComponent';
import {LandingComponent} from '../LandingComponent';

export function Routes() {
  return (
    <Switch>
      <Route {...LandingRoute.route(() => <LandingComponent />)} />
      <Route {...MainRoute.route((p) => <MainComponent params={p} />)} />
      <Route {...QrCodeRoute.route((p) => <QrCodeComponent locationToken={p.locationToken} />)} />
      <Route exact={true} path='/'>
        <Redirect to={MainRoute.makePath({destination: {type: 'default-location'}})} />
      </Route>
    </Switch>
  );
}
