import {createContext, useContext} from 'react';

export const AdaptiveContext = createContext<{width: number}>({width: 1000000});

export const mobileWidth = 770;

export function useIsSmall() {
  const c = useContext(AdaptiveContext);
  return c.width < mobileWidth;
}
