import styled, {css} from 'styled-components';
import {FlexCenterContent} from '../Utils/LayoutStyles';

export const primaryOrange = '#f2720a';
export const primaryOrangeComponents = [242, 114, 10] as [number, number, number];
export const secondaryOrange = '#F2950A';
export const brownishGrey = '#696969';
export const whiteTwo = '#f8f8f8';
export const grayTwo = '#cccccc';
export const grayOne = '#ebebeb';

export const StyledDropDown = styled.div`
  background: white;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.3);
  pointer-events: all;
`;

export const buttonSpinnerSize = 25;

export const StyledTextInput = styled.input`
  min-height: 30px;
  background: rgba(0, 0, 0, 0);
  outline: none;
  color: ${brownishGrey};
  border: 1px solid transparent;
  border-bottom: 1px solid ${primaryOrange};
  padding: 0 4px;

  &:focus {
    border-bottom: 1px solid ${secondaryOrange};
  }

  &.error {
    border: 1px solid red;
  }
`;

export const HoverStyle = css`
  cursor: pointer;
  transition: background-color 0.15s;
  &:hover {
    background-color: #eeeeee;
  }
`;

export const StyledButton = styled.button`
  ${HoverStyle}
  ${FlexCenterContent}

  font-size: 16px;
  min-width: 135px;
  min-height: 35px;
  border-radius: 5px;
  background-color: white;
  border: 0;
  color: ${primaryOrange};
`;

export const EventBackground = styled.div`
  position: relative;
  width: 100%;
  max-width: 100vw;
  margin: 20px 0;
  max-height: calc(100vh - 300px);
  overflow: hidden;
  min-width: 600px;
  min-height: 60px;
  justify-content: center;
  img {
    width: 100%;
    margin: -10% 0 0 0;
  }
`;

export const WrapUploadBtn = styled.div`
  position: absolute;
  bottom: 10px;
  right: 110px;
  width: 40px;
  height: 40px;
  .bp3-file-input {
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 5;
    input {
      display: none;
    }
  }

  .bp3-file-upload-input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    &::after {
      display: none;
    }
    opacity: 0;
    pointer-events: none;
  }
  .bp3-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    pointer-events: none;
    z-index: 10;
    border-radius: 50%;
  }
`;

export const StyleImageAvatar = styled.div`
  vertical-align: middle;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
