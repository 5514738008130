import {FlexCenterColumn, FlexCenterRow} from './Utils/LayoutStyles';
import {H4, InputGroup, NumericInput, Spinner} from '@blueprintjs/core';
import React, {useState} from 'react';
import {AsyncButton} from './Utils/AsyncButton';
import {useAsyncCallback} from './Utils/useAsyncCallback';
import {useHistory} from 'react-router';
import {MainRoute, QrCodeRoute} from './Shell/RouteDefinitions';
import {useLoginManager} from './Shell/user';
import {useAsyncEffect} from './Utils/useAsyncEffect';
import {useBackendApi} from './Api/useBackendApi';
import {LocationDtoWithId} from '../common/models';
import {HttpApi} from './Api/HttpApi';
import {useEnvironment} from './Shell/Environment';

export function QrCodeComponent({locationToken}: {locationToken: string}) {
  const [name, setName] = useState('');
  const [days, setDays] = useState(2);
  const history = useHistory();
  const api = useBackendApi();

  const lm = useLoginManager();

  const [location, setLocation] = useState<'loading' | LocationDtoWithId>('loading');

  const login = useAsyncCallback(
    async ({wrap}) => {
      await wrap(lm.loginTemporary({username: name, locationToken}));
    },
    [name, locationToken]
  );

  const env = useEnvironment();

  useAsyncEffect(
    async ({wrap}) => {
      const httpApi = new HttpApi(env.apiUrl, (options) => Promise.resolve(options));
      setLocation(await wrap(httpApi.getLocationByToken(locationToken)));
    },
    [env, locationToken]
  );

  if (lm.isLoggingIn || location === 'loading') return <Spinner />;

  if (!lm.loggedInUser) {
    return (
      <FlexCenterColumn>
        <div>You are about to join {location.name}</div>

        <div>Temporary registration:</div>
        <div>Name</div>
        <InputGroup value={name} onChange={(e) => setName(e.target.value)} />
        <AsyncButton onClickAsync={login} disabled={name === ''}>
          Register temporary account
        </AsyncButton>

        <H4>OR</H4>
        <AsyncButton
          onClickAsync={() => {
            return lm.loginAuth0(QrCodeRoute.makePath({locationToken}));
          }}
        >
          Login or create a permanent account
        </AsyncButton>
      </FlexCenterColumn>
    );
  }

  return (
    <FlexCenterColumn>
      <div>You are about to join {location.name}</div>
      <div>Stay duration:</div>
      <FlexCenterRow>
        <NumericInput min={1} max={7} value={days} onValueChange={(e) => setDays(e)} />
        <div>days</div>
      </FlexCenterRow>
      <AsyncButton
        onClickAsync={async () => {
          if (!api) return;
          await api.joinLocation({locationId: location.id, stayDurationDays: 1});
          history.push(MainRoute.makePath({destination: {type: 'default-location'}}));
        }}
      >
        Join
      </AsyncButton>
    </FlexCenterColumn>
  );
}
