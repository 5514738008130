import {ReactNode} from 'react';
import {match, RouteComponentProps} from 'react-router';
import {LocationId, MessageDestinationDto} from '../../common/models';

function trivialPath(p: string) {
  return {
    makePath: () => {
      return `/${p}`;
    },
    route: (render: () => ReactNode) => {
      return {
        exact: true,
        path: `/${p}`,
        render: () => {
          return render();
        },
      };
    },
  };
}

function paramPath<TParams>({
  parseParams,
  makePath,
  matchPaths,
}: {
  matchPaths: string[];
  makePath: (params: TParams) => string;
  parseParams: (r: match<any>) => TParams;
}) {
  return {
    makePath: (p: TParams) => {
      return makePath(p);
    },
    route: (render: (p: TParams) => ReactNode) => {
      return {
        exact: true,
        path: matchPaths,
        render: (r: RouteComponentProps<any>) => {
          const p = parseParams(r.match);
          return render(p);
        },
      };
    },
  };
}

export const ProfileRoute = {
  makePath: (id: string) => {
    return `/profile/${id}`;
  },
  route: (render: (params: {id: string}) => ReactNode) => {
    return {
      exact: true,
      path: '/profile/:id',
      render: (r: RouteComponentProps<any>) => {
        const param = r.match.params.id;
        return render({id: param});
      },
    };
  },
  isMatch: (route: string) => {
    return route.startsWith('/profile/');
  },
};

export const MyLocationsRoute = trivialPath('locations');

export const LocationRoute = paramPath<{locationId: LocationId}>({
  matchPaths: ['/locations/:locationId'],
  makePath: ({locationId}) => `/locations/${locationId}`,
  parseParams: (r) => {
    return {locationId: r.params.locationId};
  },
});

export const QrCodeRoute = paramPath<{locationToken: string}>({
  matchPaths: ['/start/:locationToken'],
  makePath: ({locationToken}) => `/start/${locationToken}`,
  parseParams: (r) => {
    return {
      locationToken: r.params.locationToken,
    };
  },
});

export const LandingRoute = trivialPath('landing');

export type MainRouteParams = 'home' | 'chats' | {destination: MessageDestinationDto};

export const MainRoute = paramPath<MainRouteParams>({
  matchPaths: ['/', '/home', '/chats', '/chat/location/:locationId', '/chat/user/:userId'],
  makePath: (params) => {
    if (params === 'home') return '/home';
    if (params === 'chats') return '/chats';
    const destination = params.destination;
    if (destination.type === 'default-location') return '/';
    else if (destination.type === 'chat') return `/chat/location/${destination.locationId}`;
    else return `/chat/user/${destination.userId}`;
  },
  parseParams: (r) => {
    if (r.path === '/home') return 'home';
    if (r.path === '/chats') return 'chats';
    if (r.params.userId) {
      return {
        destination: {type: 'direct', userId: r.params.userId},
      };
    } else if (r.params.locationId) {
      return {
        destination: {type: 'chat', locationId: r.params.locationId},
      };
    } else {
      return {
        destination: {type: 'default-location'},
      };
    }
  },
});
