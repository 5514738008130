import React from 'react';
import ReactDOM from 'react-dom';
import {Shell} from './app/Shell/Shell';

import './index.scss';
// import 'leaflet/dist/leaflet.css';
// import 'react-dropzone-uploader/dist/styles.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import L from 'leaflet';
import {Auth0Provider} from '@auth0/auth0-react';

// delete (L.Icon.Default.prototype as any)._getIconUrl;

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

fetch('/environment.json')
  .then((r) => r.json())
  .then((r) => {
    (window as any).environment = r;
    ReactDOM.render(
      <Auth0Provider
        domain={r.auth.domain}
        clientId={r.auth.clientId}
        redirectUri={window.location.origin}
        audience={r.auth.audience}
        scope={r.auth.scope}
      >
        <Shell />
      </Auth0Provider>,
      document.getElementById('root')
    );
  });

serviceWorkerRegistration.register();
