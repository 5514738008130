import {createContext, useContext} from 'react';
import {HttpApi} from './HttpApi';

export const apiContext = createContext<HttpApi | undefined>(undefined);

export function useBackendApi(): HttpApi | undefined {
  const api = useContext(apiContext);
  if (api === null) {
    return undefined;
  }
  return api;
}

export function makeAuthenticatedHttpApi(apiUrl: string, accessTokenProvider: () => Promise<string>) {
  async function addBearer(r: RequestInfo, i: RequestInit | undefined) {
    if (typeof r === 'string') {
      r = new Request(r, i);
    }

    const token = await accessTokenProvider();

    const authorization = 'Bearer ' + token;
    i = i || {headers: new Headers()};
    if (!i.headers) {
      i.headers = new Headers();
    }
    if (i.headers instanceof Headers) {
      i.headers.append('Authorization', authorization);
    } else if (Array.isArray(i.headers)) {
      i.headers.push(['Authorization', authorization]);
    } else {
      i.headers.Authorization = authorization;
    }
    return new Request(r.url, i);
  }

  return new HttpApi(apiUrl, addBearer);
}
