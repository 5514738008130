import i18n, {ResourceLanguage} from 'i18next';
import en from '../../assets/i18n/en.json';

i18n.init({
  initImmediate: false, // this actually forces to init WITHOUT setTimeout
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en as ResourceLanguage
    }
  },

  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
