import * as History from 'history';
import React from 'react';
import {Link, LinkProps} from 'react-router-dom';
import styled from 'styled-components';
import {primaryOrange} from '../Shell/Styles';

export const LinkLikeButton = styled.div`
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: ${primaryOrange};
  }
`;

export function StyledRouterLink<S = History.LocationState>({onClick, style, ...props}: LinkProps<S>) {
  return (
    <Link
      style={{color: 'unset', ...style}}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        e.stopPropagation();
      }}
      {...props}
    />
  );
}
