import React, {ReactNode, useState} from 'react';
import {FlexFiller, FlexRow} from './Utils/LayoutStyles';
import {Button, Drawer} from '@blueprintjs/core';

export function MobileHeaderComponent({title, drawerContent}: {title: ReactNode; drawerContent?: ReactNode}) {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      {drawerContent && (
        <Drawer isOpen={showDrawer} onClose={() => setShowDrawer(false)}>
          {drawerContent}
        </Drawer>
      )}
      <FlexRow
        style={{
          background: '#FC385C',
          height: '60px',
          color: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FlexFiller />
        {title}
        <FlexFiller style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '20px'}}>
          {drawerContent && <Button icon='menu' onClick={() => setShowDrawer((x) => !x)} />}
        </FlexFiller>
      </FlexRow>
    </>
  );
}
