import {DeletedUserInfo, isUser, UserDto} from '../common/models';
import {MainRoute} from './Shell/RouteDefinitions';
import {StyledRouterLink} from './Utils/NavigationLinks';
import React from 'react';

export function UserChatLink({user}: {user: UserDto | DeletedUserInfo}) {
  return (
    <StyledRouterLink
      to={MainRoute.makePath({
        destination: {
          type: 'direct',
          userId: user.id,
        },
      })}
    >
      {isUser(user) ? user.name : 'Deleted user'}
    </StyledRouterLink>
  );
}
